import React from 'react';
import Stats from './Stats';

const GlobalStats = () => {
  return (
    <div>
      <Stats />
    </div>
  );
};

export default GlobalStats;
